<template>
  <div class="stripe-balance-details-page">
    <BackTitle class="mb-32" @click="goBack">Stripe balance</BackTitle>
    <p class="stripe-balance-details-page__subtitle mb-16">Payouts</p>
    <div>
      <DashboardDetailsList
        :items="formattedPayouts"
        :infinite-id="infiniteId"
        @infinite="infiniteHandler"
      />
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import DashboardDetailsList from "@/components/dashboard/DashboardDetailsList";

export default {
  name: "StripeBalanceDetailsPage",
  components: { DashboardDetailsList, BackTitle },
  data() {
    return {
      infiniteId: +new Date(),
      limit: 15,
    };
  },
  computed: {
    ...mapState({
      payouts: (state) => state.dashboard.payouts,
      country: (state) => state.venues.selectedVenue?.country,
    }),
    formattedPayouts() {
      return this.payouts.map((payout) => ({
        name: this.getBankName(payout),
        date: this.getPayoutDate(payout),
        amount: payout.amount,
      }));
    },
  },
  created() {
    this.resetPayouts();
  },
  methods: {
    ...mapActions({
      fetchPayouts: "dashboard/fetchPayouts",
      resetPayouts: "dashboard/resetPayouts",
    }),
    async infiniteHandler($state) {
      try {
        const { length, hasMore } = await this.fetchPayouts(this.limit);
        if (length) {
          $state.loaded();
          if (!hasMore) {
            $state.complete();
          }
        } else {
          $state.complete();
        }
      } catch (e) {
        $state.complete();
      }
    },
    goBack() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    formatAmount(amount) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
      }).format(Math.abs(amount) / 100);
    },
    getBankName(payout) {
      const bank = payout.destination;
      if (bank) {
        return `${bank.bank_name} **** ${bank.last4}`;
      }
      return "Unknown bank";
    },
    getPayoutDate(payout) {
      const timestamp = payout.arrival_date;
      if (timestamp) {
        return moment.unix(timestamp).format("ddd MMM D, YYYY");
      }
      return "Unknown date";
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-balance-details-page {
  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $secondary-400;
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0 15px;
      border-bottom: 1px solid $secondary-400;

      &__bank {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $secondary-500;
      }

      &__amount {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        &.m-success {
          color: $success;
        }

        &.m-danger {
          color: $danger;
        }
      }

      &__left {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        line-height: 24px;
        color: $primary;
      }
    }
  }
}
</style>
